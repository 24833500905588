import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/structure/layout";
import styled from "styled-components";

const FourOhFour = styled.div`
  background-color: var(--background-alternate);
  font-weight: 600;
  margin-bottom: 12rem;
  padding: 1rem;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  a {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    color: var(--primary);
    background-color: var(--text-over-primary);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout pageTitle="Not Found">
      <FourOhFour className="container">
        <h1>Hmm... Page Not Found (404)</h1>
        <p>
          Sorry about that! Please visit our <Link to="/">home page</Link>.
        </p>
      </FourOhFour>
    </Layout>
  );
};

export default NotFoundPage;
